export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/(app)/billing": [5,[2]],
		"/(auth)/confirm-email/[slug]/[token]": [20,[3]],
		"/(app)/dashboard": [6,[2]],
		"/(app)/database/client": [7,[2]],
		"/(app)/database/sheet": [8,[2]],
		"/(app)/database/sheet/add": [9,[2]],
		"/(app)/database/soil": [10,[2]],
		"/(auth)/forget-password": [21,[3]],
		"/(app)/help": [11,[2]],
		"/(auth)/login": [22,[3]],
		"/(auth)/not-active": [23,[3]],
		"/(auth)/not-found": [24,[3]],
		"/(app)/plans": [12,[2]],
		"/(app)/plans/[slug]": [13,[2]],
		"/(app)/project/all": [15,[2]],
		"/(app)/project/new": [16,[2]],
		"/(app)/project/[id]/edit": [14,[2]],
		"/(auth)/register": [25,[3]],
		"/(auth)/reset-password/[slug]/[token]": [26,[3]],
		"/(app)/subscription": [17,[2]],
		"/(app)/subscription/plans": [18,[2]],
		"/(app)/user/profile": [19,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';